/*
*查询充值订单详情  http://192.168.0.26:8765/doc.html#/haolv-consumer/t-us-company-account-controller/rechargeOrderDetailUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/wallet/rechargeOrderDetail',
        data: data
    }
    return __request(pParameter)
}
export default request