import Q from 'q'
import PaymentMethodSelector200
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/payment-method-selector/1.1.0/payment-method-selector-200/index.vue';

// 查询充值订单详情
import rechargeOrderDetail from "@/lib/data-service/haolv-default/consumer_wallet_rechargeOrderDetail.js";
export default {
  name: "Recharge",
  components: {
    PaymentMethodSelector200,
  },
  data() {
    return {
      loading: false,
      detail: {},
      radio: "",
    };
  },
  methods: {
    req_detail() {
      if (this.loading) return;
      this.loading = true;
      rechargeOrderDetail({ id: this.$route.query.id })
        .then((res) => {
          this.detail = res.datas;
          this.init_payment();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init_payment() {
      const _this = this;
      const { orderNo, businessContent, dealAmount } = this.detail;
      //充值
      const init_200 = function() {
        _this.$refs.aPaymentMethodSelector200.init({
          // ui_show: {
          //     is_show_amount_to_be_paid: true,
          // },

          //在这里传入订单详情的相关参数
          get_params() {
            const p = {
              // companyId: ``,
              // clubId: ``,//中间社id
              orderNo, //订单编号
              businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里
              // isUseCredit: true,//是否允许授信支付
              subject: `充值`, //
              // orderAmount: 3000,//订单金额，用于计算可以用哪些支付方式支付
              // orderAmount: 0.05,//订单金额，用于计算可以用哪些支付方式支付
              orderAmount: dealAmount, //订单金额，用于计算可以用哪些支付方式支付
            };
            return Q.resolve(p);
          },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            // return Q.reject();
            console.log(`before_pay_event_handler`);

            return Q.resolve();
          },

          //支付成功事件处理方法
          pay_successful_event_handler() {
            console.log(`pay_successful_event_handler`);
            
            _this.$message.success('充值成功');
            _this.$router.replace({
              name: 'admin-finance-balance-list',
            })
          },

          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      };

      init_200();
    },
  },
  created() {},
  activated() {
    this.req_detail();
  },
  watch: {},
  computed: {},
};
